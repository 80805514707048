.input,
.deepLink {
    margin-top: 3rem;
}

.input input,
.deepLink input,
.input button,
.deepLink button {
    display: block;  /* Makes each element take the full width available */
    width: 90%;     /* Ensures elements use up full container width */
    margin: 1rem; /* Adds space between the elements */
}

.deepLink input {
    background-color: antiquewhite;
}

.main {
    background-color: #F1F1F1;
    padding: 1%;
    border-radius: 5%;
}